import Highway from '@dogstudio/highway';
import GlobalHeader from '../components/global/global-header';
import Faq from '../components/contacts/faq';
import Form from '../components/contacts/form';


class ContactView extends Highway.Renderer{
  onEnter() {    
    
  }
  onLeave(){

  }
  onEnterCompleted(){
    const faq = new Faq();
    const form = new Form();
    const globalHeader = new GlobalHeader();
  }
  onLeaveCompleted(){

  }
}
 
export default ContactView;
 