import gsap from 'gsap';
// import { EasePack } from "gsap/EasePack";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger); 

class GlobalHeader{
	constructor() {
		this.data = {            
      burguer: document.querySelector('.burguer input'),
      scrollUp: document.querySelector('.scroll-up'),
		};
		this.init();
    this.manageMobileMenu = this.manageMobileMenu.bind(this);    
	}
  
	init() {    
    this.burguerInit();
    onresize = (event) => {
      if(window.innerWidth >= 1024)
        gsap.set(document.querySelector('.menu'), {autoAlpha: 1})

      if(window.innerWidth < 1024 && !document.querySelector('.burguer input').checked)
        gsap.set(document.querySelector('.menu'), {autoAlpha: 0})
    };

    if(this.data.scrollUp)
      this.scrollUp();
	}

  burguerInit(){
    const that = this;
    that.data.burguer.addEventListener('click', that.manageMobileMenu);        
  }
  manageMobileMenu(event) {
    if(event.target.checked){
      gsap.set('body', {overflow: 'hidden'});      
      gsap.to(document.querySelector('.menu'), {autoAlpha: 1, duration:.25, delay: .1, ease: "power4.inOut"})      
    }
    else{
      gsap.to(document.querySelector('.menu'), {autoAlpha: 0, duration:.25, delay: .1, ease: "power4.inOut"})
      gsap.set('body', {overflow: 'inherit'});      
    }
  }

  scrollUp() {
    gsap.utils.toArray(".scroll-up").forEach(element => {            
        ScrollTrigger.create({
            trigger: element,
            onEnter: () => {
                gsap.fromTo(element, {y: 100, opacity: 0},{y: 0, opacity: 1, duration: 1.2, ease: "expo.out"});
            },
            onLeaveBack: () => {
                gsap.fromTo(element, {y: 0, opacity: 1},{y: 100, opacity: 0, duration: 1.2, ease: "expo.out"});
            },
            onEnterBack: () => { 
                gsap.fromTo(element, {y: -100, opacity: 0},{y: 0, opacity: 1, duration: 1.2, ease: "expo.out"});
            },
        });
    })
  }
}


export default GlobalHeader;