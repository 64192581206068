import gsap from 'gsap';
import axios from 'axios';
import highway from '@dogstudio/highway';

class Filters{
	constructor() {
		this.data = {
      filters: document.querySelector('.filters'),
      pag: document.querySelector('.pag'),
		};
		this.init();        
	}
  
	init() {
    this.changeFilter();
    // this.pagination();
	}    

  pagination(event) {    
    this.data.pag.querySelectorAll('span[data-url]').forEach(page => {      
      page.addEventListener('click', event => {
        console.log('asd');
        event.preventDefault();
        event.stopPropagation();      
        let url = event.target.getAttribute('data-url');
        console.log(url)
        window.history.pushState({}, '', url);      
        gsap.to('.list .articles-list', {opacity: .3, pointerEvents: 'none'});
        axios
            .get(url)
            .then((response) => {
              var parser = new DOMParser();
              var xmlDoc = parser.parseFromString(response.data, "text/html");
              document.querySelector('.list .articles-list').innerHTML = xmlDoc.querySelector('.list .articles-list').outerHTML;
              document.querySelector('.pag').innerHTML = xmlDoc.querySelector('.pag').outerHTML;
              setTimeout(() => {
                highway.updateLinks();
              }, 1000);
              gsap.to('.list .articles-list', {opacity: 1, pointerEvents: 'all'});
        });     
      })
    });
  }

  changeFilter(event) {
    let that = this;
    this.data.filters.querySelectorAll('span').forEach(filter => {
      filter.addEventListener('click', event => {
        event.preventDefault();
        event.stopPropagation();
        let cat = event.target.dataset.cat;
        let url = event.target.getAttribute('data-url');
        window.history.pushState({}, '', url);
        this.data.filters.querySelector('.active').classList.remove('active');
        event.target.classList.add('active');
        // gsap.to(that.data.catMask, {visibility: 'visible', opacity: 1, duration: .2});
        // axios
        //     .get("/wp-json/blog/v1/changeCat/?cat="+cat)
        //     .then((response) => {
        //       console.log(response);
        //       document.querySelector('.articles-list').innerHTML = '';
        //       if(response.data.pagination != null){
        //         document.querySelector('.pag').innerHTML = `${response.data.pagination}`;
        //       }
        //       else{
        //         document.querySelector('.pag').innerHTML = '';
        //       }
                  
        //       response.data.posts.forEach(post => {
        //         document.querySelector('.articles-list').innerHTML += `
        //           <div class="article" data-cat="${post.catID}">  
        //             <a href="${post.url}" alt="${post.title}">             
        //               ${post.image}
        //               <span style="${post.catColor}">${post.cat}</span>
        //               <h5>${post.title}</h5>
        //               <div class="date">
        //                 <p>${post.time}</p>
        //                 <p>${post.views}</p>
        //               </div>                      
        //             </a>
        //           </div>
        //         `   
        //       });     
        //       highway.updateLinks();                
        //       // gsap.to(that.data.catMask, {visibility: 'hidden', opacity: 0, duration: .2})
        //     });     

        gsap.to('.list .articles-list', {opacity: .3, pointerEvents: 'none'});
        axios
            .get(url)
            .then((response) => {
              var parser = new DOMParser();
              var xmlDoc = parser.parseFromString(response.data, "text/html");              
              document.querySelector('.list .articles-list').innerHTML = xmlDoc.querySelector('.list .articles-list').outerHTML;
              document.querySelector('.pag').innerHTML = xmlDoc.querySelector('.pag').outerHTML;              
              setTimeout(() => {
                highway.updateLinks();
              }, 1000);
              gsap.to('.list .articles-list', {opacity: 1, pointerEvents: 'all'});
        });     
      })
    });
  }
}


export default Filters;