import Highway from '@dogstudio/highway';
import GlobalHeader from '../components/global/global-header';
import HomeFeatures from '../components/home/features';


class HomeView extends Highway.Renderer{
  onEnter() {    
    
  }
  onLeave(){

  }
  onEnterCompleted(){
    const globalHeader = new GlobalHeader();
    const features = new HomeFeatures();
  }
  onLeaveCompleted(){

  }
}
 
export default HomeView;
 