import gsap from 'gsap';
// import {ScrollTrigger} from 'gsap/ScrollTrigger';
// import {DrawSVGPlugin} from "../../util/drawSVG";
// gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

class HomeFeatures{
	constructor() {
		this.data = {

		};
		this.init();        
	}
  
	init() {
    document.querySelectorAll('.home .features .content-wrapper').forEach(element => {
      element.querySelector('h4').addEventListener('click', event => {        
        let id = event.target.parentElement.getAttribute('data-id');

        document.querySelector('.content-wrapper.active').classList.remove('active')
        event.target.parentElement.classList.add('active');
        
        gsap.fromTo('.home .features img.active', {y: 0, autoAlpha: 1}, {y: '-20px', autoAlpha: 0});
        document.querySelector('.home .features img.active').classList.remove('active');                
        
        gsap.fromTo(document.querySelector('.home .features img[data-id="'+id+'"]'), {y:'22px', autoAlpha: 0}, {y: 0, autoAlpha: 1})
        document.querySelector('.home .features img[data-id="'+id+'"]').classList.add('active');        
        
        
      })
    });
	}    
}


export default HomeFeatures;