import Highway from '@dogstudio/highway';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
const imagesLoaded = require('imagesloaded');
gsap.registerPlugin(ScrollTrigger);

// SLIDE
class Slide extends Highway.Transition {
  in({ from, to, done }) {    
    document.querySelector('body').className = to.getAttribute('class');
    window.scrollTo(0, 0);
    // Remove Old View and section body_class
    from.remove(); 
    document.querySelector('main > div').className = '';

    if(document.querySelector('link[rel="canonical"]'))
      document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);

    gsap.set(document.querySelector('main'), {opacity: 0});    

    imagesLoaded( document.querySelector('body'), function( instance ) {
      gsap.to(document.querySelector('.load-screen'), {opacity: 0, duration:.3, ease:"power4.inOut", onComplete: function(){
          gsap.set(document.querySelector('body'), {overflow: 'inherit'});
          // gsap.set(document.querySelector('.load-screen'), {opacity: 1});
      
          gsap.to(document.querySelector('main'), {opacity: 1, duration: .1});
          gsap.to('.main-header', {opacity: 1,y: 0,})
                
          document.body.style.pointerEvents = 'all';
          done();          
      }})
    }); 
  }  
  
  out({ from, done }) {
    // Animation    
    document.body.style.pointerEvents = 'none';    
    gsap.set(document.querySelector('body'), {overflow: 'hidden'});
    gsap.to(document.querySelector('.load-screen'), {opacity:1, duration:0.5, delay: 0.1, ease:"power4.inOut", onComplete: function(){ 
      // Reset Scroll
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
      ScrollTrigger.refresh();
      done();
     
    }})
  }
  
}

export default Slide;

