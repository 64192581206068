import gsap from 'gsap';
import axios from "axios";
import { resolveConfig } from 'prettier';

class Form{
	constructor() {
		this.data = {
      form: document.querySelector('#contact-form'),
      modal: document.querySelector('.form-modal')
		};
		this.init();        
    this.handlerSubmit = this.handlerSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
	}
  
	init() {		    
    this.data.form.addEventListener("submit", this.handlerSubmit);
    this.data.modal.querySelector('.close').addEventListener('click', this.closeModal);

    document.querySelectorAll('input').forEach(element => {
      element.addEventListener('focus', this.changePlaceholder)
      element.addEventListener('blur', this.changePlaceholderBack)
    });
    document.querySelector('textarea').addEventListener('focus', this.changePlaceholder);
    document.querySelector('textarea').addEventListener('blur', this.changePlaceholderBack);
	}    

  changePlaceholder(event){
    let placeholder = event.target.placeholder;
    event.target.placeholder = "Type "+placeholder;
  }

  changePlaceholderBack(event) {
    let placeholder = event.target.getAttribute('data-placeholder');
    console.log(placeholder)
    event.target.placeholder = placeholder;
  }

  closeModal() {
    let that = this;
    gsap.to(document.querySelector('.form-modal'), {autoAlpha: 0});
  }

  handlerSubmit (event) {    
    let that = this;    
    event.preventDefault();
    event.target.querySelector("button[type=submit]").style.opacity = ".5";
    event.target.querySelector("button[type=submit]").style.pointerEvents = "none";

    let formData = new FormData();
    formData.append("name", event.target.querySelector('input[name="name"').value);
    formData.append("email", event.target.querySelector('input[name="email"').value);
    formData.append("message", event.target.querySelector('textarea').value);
    formData.append('action', 'contactForm');
    console.log(window.location)
    let url =  window.location.origin+'/wp-admin/admin-ajax.php';
    axios({
            method: "post",
            url: url,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(function (response) {
          
            console.log(response);
            if(response.status == 200){
              event.target.querySelector("button[type=submit]").style.opacity = "1";
              event.target.querySelector("button[type=submit]").style.pointerEvents = "all";
              document.querySelector('.form-modal h2').innerHTML = response.data.title;
              document.querySelector('.form-modal p').innerHTML = response.data.subTitle;
              document.querySelector('.form-modal div.img').innerHTML = response.data.image;
              gsap.fromTo(document.querySelector('.form-modal'), {autoAlpha: 0, y:10}, {autoAlpha: 1, y:0});
            }
            else{
              event.target.querySelector("button[type=submit]").style.opacity = "1";
              event.target.querySelector("button[type=submit]").style.pointerEvents = "all";
              document.querySelector('.form-modal h2').innerHTML = response.data.title;
              document.querySelector('.form-modal p').innerHTML = response.data.subTitle;
              document.querySelector('.form-modal div.img').innerHTML = response.data.image;
              gsap.to(document.querySelector('.form-modal'), {autoAlpha:0, y:20}, {autoAlpha: 1, y:0});
            }					
          })		
  }
}


export default Form;