import gsap from 'gsap';

class Faq{
	constructor() {
		this.data = {
      cards: document.querySelectorAll('.faq .card')
		};
		this.init();        
	}
  
	init() {		
    this.data.cards.forEach(card => {
      let cardHeight = card.querySelector('p').offsetHeight;
      card.querySelector('p').setAttribute('data-height', cardHeight);
      gsap.set(card.querySelector('p'), {height: 0});
      card.addEventListener('click', this.manageAccordeon);
    });
	}    

  manageAccordeon(event) {
    let that = this;    
    let pHeight = event.target.querySelector('p').getAttribute('data-height');
    if(event.target.classList.contains('open')){
      event.target.classList.remove('open');
      gsap.to(event.target.querySelector('p'), {height: '0px', ease: 'power4.out'});
      gsap.to(event.target.querySelector('svg'), {rotate: 0, ease: 'power4.out'})
    }
    else{      
      event.target.classList.add('open');
      gsap.to(event.target.querySelector('p'), {height: pHeight, ease: 'power4.out'});
      gsap.to(event.target.querySelector('svg'), {rotate: 180, ease: 'power4.out'})
    }
  }
}


export default Faq;