import Highway from '@dogstudio/highway';
import GlobalHeader from '../components/global/global-header';


class SingleBlogView extends Highway.Renderer{
  onEnter() {    
    
  }
  onLeave(){

  }
  onEnterCompleted(){
    const globalHeader = new GlobalHeader();
  }
  onLeaveCompleted(){

  }
}
 
export default SingleBlogView;
 