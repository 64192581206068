import Highway from '@dogstudio/highway';
import Filters from '../components/blog/filters';
import GlobalHeader from '../components/global/global-header';


class ContactView extends Highway.Renderer{
  onEnter() {    
    
  }
  onLeave(){

  }
  onEnterCompleted(){
    const filters = new Filters();
    const globalHeader = new GlobalHeader();
  }
  onLeaveCompleted(){

  }
}
 
export default ContactView;
 